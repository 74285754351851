import React from "react";

import Header from "../../Components/Header/Header";
import ImageChartMenu from "../../Components/ImageChartMenu/ImageChartMenu";
import Footer from "../../Components/rus/Footer/Footer";
import DocsTable from "../../Components/DocsTable/DocsTable";
import storDef from "../../Store/Default";
import { observer } from "mobx-react-lite";
const Index = observer(() => {
    const docsListRus={
        all: [
            { type: "pdf", name: "Правила торгов", url: "/docs/rus/tradingrules_ru.pdf" },
            { type: "pdf", name: "Правила проведения транзакций", url: "/docs/rus/transactions_ru.pdf" },
            { type: "pdf", name: "Комиссии", url: "/docs/rus/fees_ru.pdf" },
            { type: "pdf", name: "Риск-параметры", url: "/docs/rus/riskparameters_ru.pdf" },
            { type: "pdf", name: "Пользовательское соглашение", url: "/docs/rus/terms_ru.pdf" },
            { type: "pdf", name: "Уведомление о рисках", url: "/docs/rus/risknotice_ru.pdf" },
            { type: "pdf", name: "Соглашение об обработке персональных данных", url: "/docs/rus/personal_ru.pdf" },
          ],
          instruments: [
            { type: "pdf", name: "Индекс AE BTC", url: "/docs/rus/btcindex_ru.pdf" },
            { type: "pdf", name: "Индекс AE ETH", url: "/docs/rus/ethindex_ru.pdf" },
            { type: "pdf", name: "Спецификация фьючерс BTC", url: "/docs/rus/btcfutures_ru.pdf" },
            { type: "pdf", name: "Спецификация фьючерс ETH", url: "/docs/rus/ethfutures_ru.pdf" },
            { type: "pdf", name: "Спецификация опционы BTC", url: "/docs/rus/btcoptions_ru.pdf" },
            { type: "pdf", name: "Спецификация опционы ETH", url: "/docs/rus/ethoptions_ru.pdf" },
            { type: "pdf", name: "Спецификация фьючерс MATIC", url: "/docs/rus/maticfutures_ru.pdf" },
            { type: "pdf", name: "Спецификация опционы MATIC", url: "/docs/rus/maticoptions_ru.pdf" },
            { type: "pdf", name: "Спецификация фьючерс DOGE", url: "/docs/rus/Dogefutures_ru.pdf" },
            { type: "pdf", name: "Спецификация опционы DOGE", url: "/docs/rus/dogeoptions_ru.pdf" },
            { type: "pdf", name: "Спецификация фьючерс SOL", url: "/docs/rus/SOLfutures_ru.pdf" },
            { type: "pdf", name: "Спецификация опционы SOL", url: "/docs/rus/soloptions_ru.pdf" },
            { type: "pdf", name: "Спецификация фьючерс TON", url: "/docs/rus/TONfutures_ru.pdf" },
            { type: "pdf", name: "Спецификация опционы TON", url: "/docs/rus/TONoptions_ru.pdf" },
    
          ],
          work_docs: [
            { type: "pdf", name: "Описание десктоп терминала", url: "/docs/Manual_desktop_terminal_AE.pdf" },
            { type: "pdf", name: "Описание веб терминала", url: "/docs/Web_terminal_AE_manual_RU_Eng.pdf" },
            { type: "pdf", name: "Инструкция. Как получить доступ к торговле", url: "/docs/dostup_AE.pdf" },
            { type: "pdf", name: "Примеры. Как пополнить счет АЕ", url: "/docs/how_to_top_up_an_ae_account.pdf" },
            { type: "pdf", name: "Спецификация протокола подключения к бирже <br> client api websocket v3 от 10.05.2023 (AE Game только)", url: "/docs/ClientAPI.pdf" },
            { type: "pdf", name: "Пример клиентского API-протокола (AE Game только)", url: "/docs/test_client_api.py" },
            { type: "pdf", name: "Сервис Decentral Deposit AE", url: "/docs/DD_AE_ru.pdf" },
          ],
        }
       const docsListEng= {
          all: [
            { type: "pdf", name: "Trading rules", url: "/docs/eng/tradingrules_en.pdf" },
            { type: "pdf", name: "Transaction rules", url: "/docs/eng/transactions_en.pdf" },
            { type: "pdf", name: "Commissions", url: "/docs/eng/fees_en.pdf" },
            { type: "pdf", name: "Risk parameters", url: "/docs/eng/riskparameters_en.pdf" },
            { type: "pdf", name: "Terms of use", url: "/docs/eng/terms_en.pdf" },
            { type: "pdf", name: "Risk disclosure", url: "/docs/eng/risknotice_en.pdf" },
            { type: "pdf", name: "Personal data processing agreement", url: "/docs/eng/personal_en.pdf" },
          ],
          instruments: [
            { type: "pdf", name: "AE BTC Index", url: "/docs/eng/btcindex_en.pdf" },
            { type: "pdf", name: "AE ETH Index", url: "/docs/eng/ethindex_en.pdf" },
            { type: "pdf", name: "BTC futures specification", url: "/docs/eng/btcfutures_en.pdf" },
            { type: "pdf", name: "BTC options specification", url: "/docs/eng/btcoptions_en.pdf" },
            { type: "pdf", name: "ETH futures specification", url: "/docs/eng/ethfutures_en.pdf" },
            { type: "pdf", name: "ETH options specification", url: "/docs/eng/ethoptions_en.pdf" },
            { type: "pdf", name: "MATIC futures specification", url: "/docs/eng/maticfutures_en.pdf" },
            { type: "pdf", name: "MATIC options specification", url: "/docs/eng/maticoptions_en.pdf" },
            { type: "pdf", name: "DOGE futures specification", url: "/docs/eng/dogefutures_en.pdf" },
            { type: "pdf", name: "DOGE options specification", url: "/docs/eng/dogeoptions_en.pdf" },
            { type: "pdf", name: "SOL futures specification", url: "/docs/eng/SOLfutures_en.pdf" },
            { type: "pdf", name: "SOL options specification", url: "/docs/eng/soloptions_en.pdf" },
            { type: "pdf", name: "TON futures specification", url: "/docs/eng/TONfutures_en.pdf" },
            { type: "pdf", name: "TON options specification", url: "/docs/eng/TONoptions_en.pdf" },
          ],
          work_docs: [
            { type: "pdf", name: "Trading terminal manual", url: "/docs/userguide.pdf" },
            { type: "pdf", name: "Web terminal manual", url: "/docs/Web_terminal_AE_manual_eng.pdf" },
            { type: "pdf", name: "Client api websocket protocol specification (AE Game only)", url: "/docs/ClientAPI.pdf" },
            { type: "pdf", name: "Client api protocol example (AE Game only)", url: "/docs/test_client_api.py" },
            { type: "pdf", name: "Decentral Deposit AE Service", url: "/docs/DD_AE_eng.pdf" },
          ],
        };
        let ls=storDef.getLang()
  let docsList=docsListRus;
  if( ls===2){
    docsList=docsListEng;
    return (
      <div className="body">
        <Header />
  
        <ImageChartMenu titleImg="Documents" textImg="Rules, contract specifications"/>
  
        <div className="page_content">
          <div className="page_content_left">
            <DocsTable title="General"  list={docsList.all} />
            <div style={{marginTop:"20px"}}></div>
           < DocsTable title="User Documentation"  list={docsList.work_docs}  />
          </div>
          <div className="page_content_vline"></div>
          <div className="page_content_right">
            <DocsTable title="Trading instruments"  list={docsList.instruments} />
          </div>
        </div>
        <div className="page_footer">
          <Footer />
        </div>
      </div>
    );
  }
  return (
    <div className="body">
      <Header />

      <ImageChartMenu titleImg="Документы" textImg="Правила, спецификации и описания"/>

      <div className="page_content">
        <div className="page_content_left">
          <DocsTable title="Общее"  list={docsList.all} />
          <div style={{marginTop:"20px"}}></div>
         < DocsTable title="Пользовательская документация"  list={docsList.work_docs}  />
        </div>
        <div className="page_content_vline"></div>
        <div className="page_content_right">
          <DocsTable title="Торговые инструменты"  list={docsList.instruments} />
        </div>
      </div>
      <div className="page_footer">
        <Footer />
      </div>
    </div>
  );
})

export default Index;
